<template>
  <div
    ref="container"
    class="marquee"
    v-resize="onResize"
    :class="{
      reset: reset,
      visible: repeat !== 0
    }"
  >
    <span ref="text">{{text}}</span>
    <span v-for="i in repeat" :key="i">{{text}}</span>
  </div>
</template>

<script>
export default {
  name: 'MarqueeText',
  props: {
    text: { type: String }
  },
  data () {
    return {
      repeat: 0,
      reset: true
    }
  },
  mounted () {
    this.$nextTick(this.onResize)
  },
  methods: {
    onResize () {
      clearTimeout(this.timeout)
      const containerW = this.$refs.container.offsetWidth
      const spanW = this.$refs.text.offsetWidth

      this.reset = true
      this.repeat = 1 + ~~(containerW / spanW)
      this.timeout = setTimeout(() => (this.reset = false), 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.marquee {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  overflow: hidden;
  font-size: 0;

  &.reset span {
    animation: none;
  }
}

.visible {
  opacity: 1;
}

span {
  font-size: rem(16px);
  display: inline-block;
  padding-left: rem(8px);
  will-change: transform;
  animation: marquee 5s linear infinite forwards;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>
