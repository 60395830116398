import { render, staticRenderFns } from "./MarqueeText.vue?vue&type=template&id=3367d800&scoped=true&"
import script from "./MarqueeText.vue?vue&type=script&lang=js&"
export * from "./MarqueeText.vue?vue&type=script&lang=js&"
import style0 from "./MarqueeText.vue?vue&type=style&index=0&id=3367d800&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3367d800",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})
