<template>
  <BasePage :isLoaded="isLoaded" full disableFooter disablePreFooter>
    <template v-slot:content>
      <VideoFX
        class="bg -full -below"
        :videoSrc="`/media/${homePageContent.homeBgVideo.id}`"
      />
      <div class="home-content -above">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              offset-md="1"
              md=10
            >
              <Cta
                class="link h1"
                colorSchema="none"
                action="internal"
                label="We are Regi"
                :to="{ name: 'WeAreRegi'}"
              />

              <Cta
                class="link h1"
                colorSchema="none"
                action="internal"
                :label="homePageContent.homeRightPage.title"
                :to="{ name: 'ModularPage', params: { slug: homePageContent.homeRightPage._slug }}"
              />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <footer class="-above pb-4">
        <v-container fluid>
          <v-row align="center">
            <v-col
              v-if="homePageContent.isHomeCtaEnabled"
              order="1"
              cols="6"
              sm="4"
              md="2"
              class="text-center text-md-left"
            >
              <Cta
                colorSchema="none"
                action="internal"
                :label="homePageContent.homeVirtualTourCta.label"
                :to="homePageContent.homeVirtualTourCta.url"
              />
            </v-col>
            <v-col
              order="2"
              cols="6"
              :sm="homePageContent.isHomeCtaEnabled ? 4 : 6"
              order-sm="3"
              md="2"
              class="text-center"
            >
              <Cta
                colorSchema="none"
                action="events-calendar"
                label="Events Calendar"
              />
            </v-col>
            <v-col
              order="3"
              :cols="homePageContent.isHomeCtaEnabled ? 12 : 6"
              :sm="homePageContent.isHomeCtaEnabled ? 4 : 6"
              order-sm="2"
              md="2"
              :class="(!homePageContent.isHomeCtaEnabled ? 'text-lg-left' : 'text-lg-right')"
              class="text-center"
            >
              <span class="vat-number">{{ homePageContent.homeVatNumber }}</span>
            </v-col>
            <v-col
              order-md="4"
              :md="homePageContent.isHomeCtaEnabled ? 6 : 8"
              class="d-none d-md-block"
            >
              <MarqueeText v-if="event" class="marquee" :text="event.title" />
            </v-col>
          </v-row>
        </v-container>
      </footer>
    </template>
  </BasePage>
</template>

<script>
import GetHomePage from '@/graphql/GetHomePage.gql'
import ListEventQuery from '@/graphql/ListEvent.gql'
import BasePage from '@/organisms/BasePage'
import VideoFX from '@/atoms/VideoFx'
import MarqueeText from '@/atoms/MarqueeText'
import Cta from '@/molecules/Cta'

export default {
  name: 'HomePage',

  components: {
    BasePage,
    VideoFX,
    MarqueeText,
    Cta
  },

  apollo: {
    homePageContent: {
      query: GetHomePage,
      variables () {
        return {
          slug: 'globals'
        }
      }
    },
    Events: {
      query: ListEventQuery,
      variables () {
        return {
          filter: this.eventFilter,
          sortOn: 'endDate',
          descending: false,
          limit: 1
        }
      },
      async result (res) {
        if (res.data.Events.items.length < 1) {
          this.eventFilter = null
        } else {
          this.event = res.data.Events.items[0]
        }
      }
    },
    EventsFallback: {
      query: ListEventQuery,
      variables: {
        sortOn: 'endDate',
        descending: false,
        limit: 1
      },
      skip () {
        return this.eventFallback
      },
      async result (res) {
        this.event = res.data.Events.items[0]
      }
    }
  },
  data () {
    return {
      event: null,
      eventFilter: {
        field: 'endDate',
        operator: 'ge',
        operand: new Date().toISOString()
      },
      videoPlaying: false
    }
  },

  computed: {
    isLoaded () {
      return !!this.homePageContent
    }
  }
}
</script>

<style scoped lang="scss">
.home-content {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  pointer-events: none;
  min-height: 100%;

  a {
    pointer-events: auto;
    text-decoration: none;
  }
}

.link {
  display: block;

  @include breakpoint('md-and-up') {
    display: inline-block;
    max-width: rem(350px);

    ::v-deep .pre-label {
      margin-top: 0;
      stroke-width: 0.7px;
      pointer-events: none;
    }
  }
}

.link:first-child {
  ::v-deep .pre-label {
    @include breakpoint('md-and-up') {
      top: 100%;
    }
  }
}

.link:last-child {
  @include breakpoint('md-and-down') {
    margin-top: rem(24px);
  }

  @include breakpoint('lg-and-up') {
    margin-left: rem(50px);
  }

  ::v-deep .pre-label {
    transform: scaleY(-1);
    @include breakpoint('md-and-up') {
      top: auto;
      bottom: 100%;
    }
  }

  &:hover ::v-deep .pre-label {
    animation: ctaArrowReverse 0.5s;
  }
}

@keyframes ctaArrowReverse {
  0% {
    transform: scaleY(-1) translate(0%, 0%);
    opacity: 1;
  }

  33% {
    transform: scaleY(-1) translate(50%, -50%);
    opacity: 0;
  }

  34% {
    transform: scaleY(-1) translate(-50%, 50%);
    opacity: 0;
  }

  100% {
    transform: scaleY(-1) translate(0%, 0%);
    opacity: 1;
  }
}

.marquee {
  /* stylelint-disable */
  /* autoprefixer: ignore next */
  -webkit-mask-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 20px, rgba(0, 0, 0, 1) calc(100% - 20px), rgba(0, 0, 0, 0) 100%);
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 20px, rgba(0, 0, 0, 1) calc(100% - 20px), rgba(0, 0, 0, 0) 100%);
  /* stylelint-enable */
  ::v-deep span {
    padding-right: rem(80px);
    position: relative;

    &::after {
      content: '';
      display: inline-block;
      height: 1px;
      width: rem(60px);
      background: $color-text-dark;
      position: absolute;
      right: rem(5px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.vat-number {
  opacity: 0.5;
}
</style>
