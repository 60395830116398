<template>
  <div class="video-container" v-cursormove="onCursorMove" v-viewport.debounce:150="onResize">
    <!-- <dat-gui v-if="$root.process.env.SERVICE_STAGE !== 'production'">
      <dat-boolean v-model="isFixedRadius" label="FixedRadius"/>
      <dat-number v-model="radius" :min="0" :max="1" :step="0.001" label="Radius"/>
      <dat-number v-model="displacementR" :min="-400" :max="400" :step="0.1" label="Red"/>
      <dat-number v-model="displacementG" :min="-400" :max="400" :step="0.1" label="Green"/>
      <dat-number v-model="displacementB" :min="-400" :max="400" :step="0.1" label="Blue"/>
      <dat-number v-model="displacementDivider" :min="10" :max="10000" :step="1" label="Divider"/>
    </dat-gui> -->
    <video
      ref="video"
      class="-full -below"
      muted autoplay playsinline loop
      @loadedmetadata="onLoadedMeta"
      @playing="onVideoPlaying"
      :src="videoSrc"
    ></video>
    <canvas
      ref="canvas"
      class="video-fx -full -above"
      :class="{visible: videoIsPlaying}"
    ></canvas>
  </div>
</template>

<script>
import { Renderer, Geometry, Program, Mesh, Texture, Vec2 } from 'ogl'
import { ElasticNumber, RAF } from '@monogrid/js-utils'
import DeltaTime from '@/mixins/DeltaTime'
import { menuEvent, startProjectEvent } from '@/constants'
import ActiveStateListenerMixin from '@/mixins/ActiveStateListenerMixin'

const modalListenerMixin = ActiveStateListenerMixin(startProjectEvent, 'modalIsOpen')
const menuListenerMixin = ActiveStateListenerMixin(menuEvent, 'menuIsOpen')

const vertexShader = `precision highp float;
attribute vec2 position;

void main() {
  // Look ma! no projection matrix multiplication,
  // because we pass the values directly in clip space coordinates.
  gl_Position = vec4(position, 0., 1.0);
}`

const fragmentShader = `precision highp float;
uniform sampler2D uTexture;
uniform vec2 uResolution;
uniform vec2 uImgResolution;
uniform vec2 uMouse;
uniform float uMouseVelocity;
uniform float uRadius;
uniform float uDisplacementR;
uniform float uDisplacementG;
uniform float uDisplacementB;

#define PI 3.1415926538

void main() {
  vec2 vUv = gl_FragCoord.xy / uResolution.xy;
  vec2 mouse = uMouse.xy / uResolution.xy;
  float distance = length(gl_FragCoord.xy - uMouse);

  float radius = uRadius * uMouseVelocity;
  radius = min(uResolution.x, uResolution.y) * radius;
  float normalizedDistance = 1. - (min(radius,distance)/radius);
  normalizedDistance *= normalizedDistance * 1.;
  float scale = 1. - normalizedDistance;
  vUv = (vUv - mouse) * scale + mouse;

  vec2 ratio = vec2(
    min((uResolution.x / uResolution.y) / (uImgResolution.x / uImgResolution.y), 1.0),
    min((uResolution.y / uResolution.x) / (uImgResolution.y / uImgResolution.x), 1.0)
  );

  vec2 uv = vec2(
    vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
    vUv.y * ratio.y + (1.0 - ratio.y) * 0.5
  );

  vec4 color;

  if(distance < radius){
    float r = texture2D(uTexture, uv += normalizedDistance * uDisplacementR).x;
    float g = texture2D(uTexture, uv += normalizedDistance * uDisplacementG).y;
    float b = texture2D(uTexture, uv += normalizedDistance * uDisplacementB).z;
    color = vec4(r, g, b, 1.);
  }else{
    vec4 texture = texture2D(uTexture, uv );
    color = texture;
  }
  gl_FragColor = mix(vec4(1.), color, 0.7);
}`

export default {
  props: {
    videoSrc: {
      type: String,
      required: true
    }
  },
  mixins: [DeltaTime, modalListenerMixin, menuListenerMixin],
  data () {
    return {
      videoIsReady: false,
      videoIsPlaying: false,
      radius: 0.7,
      isFixedRadius: false,
      displacementR: 2,
      displacementG: 1,
      displacementB: -2,
      displacementDivider: 200
    }
  },
  created () {
    this.mouseX = new ElasticNumber(0)
    this.mouseX.speed = 10
    this.mouseY = new ElasticNumber(0)
    this.mouseY.speed = 10
    this.mouseScale = new ElasticNumber(0.001)
    this.mouseScale.speed = 1
  },
  mounted () {
    window.addEventListener('touchstart', this.onWindowTouch)
  },
  watch: {
    radius (value) {
      this.material.uniforms.uRadius.value = value
    },
    displacementR (value) {
      this.material.uniforms.uDisplacementR.value = value / this.displacementDivider
    },
    displacementG (value) {
      this.material.uniforms.uDisplacementG.value = value / this.displacementDivider
    },
    displacementB (value) {
      this.material.uniforms.uDisplacementB.value = value / this.displacementDivider
    },
    displacementDivider (value) {
      this.material.uniforms.uDisplacementR.value = this.displacementR / this.displacementDivider
      this.material.uniforms.uDisplacementG.value = this.displacementG / this.displacementDivider
      this.material.uniforms.uDisplacementB.value = this.displacementB / this.displacementDivider
    }
  },
  beforeDestroy () {
    window.removeEventListener('touchstart', this.onWindowTouch)
    RAF.remove(this.render)
    if (this.renderer) {
      this.geometry.remove()
      this.material.remove()
      this.renderer.gl.deleteTexture(this.texture.texture)
      this.renderer.gl.bindTexture(this.renderer.gl.TEXTURE_2D, null)
      this.renderer.gl.bindTexture(this.renderer.gl.TEXTURE_CUBE_MAP, null)
      this.renderer.gl = null
      this.renderer = null
    }
  },

  methods: {
    onVideoPlaying () {
      this.videoIsPlaying = true
      window.removeEventListener('touchstart', this.onWindowTouch)
    },
    onWindowTouch () {
      if (this.$refs.video && !this.videoIsPlaying) {
        this.$refs.video.play()
      }
    },
    createScene () {
      this.renderer = new Renderer({
        canvas: this.$refs.canvas,
        width: window.innerWidth,
        height: window.innerHeight
      })
      this.createMesh()
    },
    createMesh () {
      this.createGeometry()
      this.createMaterial()

      this.triangle = new Mesh(this.renderer.gl, { geometry: this.geometry, program: this.material })
    },
    createGeometry () {
      this.geometry = new Geometry(this.renderer.gl, {
        // eslint-disable-next-line
        position: {size: 2, data: new Float32Array([-1, -1, 3, -1, -1, 3])},
        // eslint-disable-next-line
        uv: {size: 2, data: new Float32Array([0, 0, 2, 0, 0, 2])},
      })
    },
    createMaterial () {
      this.$refs.video.crossOrigin = 'anonymous'
      this.texture = new Texture(this.renderer.gl, {
        generateMipmaps: false,
        width: this.$refs.video.videoWidth,
        height: this.$refs.video.videoHeight,
        image: this.$refs.video
      })
      this.resolution = new Vec2(window.innerWidth, window.innerHeight)
      this.imgResolution = new Vec2(window.innerWidth, window.innerHeight)
      this.mouse = new Vec2(0.5, 0.5)
      this.material = new Program(this.renderer.gl, {
        vertex: vertexShader,
        fragment: fragmentShader,
        uniforms: {
          uRadius: { value: this.radius },
          uTexture: { value: this.texture },
          uResolution: { value: this.resolution },
          uImgResolution: { value: this.imgResolution },
          uMouse: { value: this.mouse },
          uMouseVelocity: { value: this.mouseScale.value },
          uDisplacementR: { value: this.displacementR / this.displacementDivider },
          uDisplacementG: { value: this.displacementG / this.displacementDivider },
          uDisplacementB: { value: this.displacementB / this.displacementDivider }
        }
      })
    },
    onLoadedMeta () {
      this.createScene()
      this.onResize(window.innerWidth, window.innerHeight)
      RAF.add(this.render)
      this.videoIsReady = true
    },
    onCursorMove (x, y) {
      this.mouseScale.target = 1
      this.mouseX.target = x
      this.mouseY.target = y
    },
    onResize (width, height) {
      if (!this.renderer) {
        return
      }
      this.renderer.setSize(width, height)
      // this.renderer.getDrawingBufferSize(this.resolution)

      this.resolution.x = width
      this.resolution.y = height

      this.imgResolution.x = this.$refs.video.videoWidth
      this.imgResolution.y = this.$refs.video.videoHeight

      this.material.uniforms.uImgResolution.value = this.imgResolution
    },
    render () {
      if (!this.videoIsPlaying) return
      const delta = this.getDelta()
      this.mouseX.update(delta)
      this.mouseY.update(delta)
      this.mouseScale.update(delta)
      this.mouseScale.target = 0
      // not update shader and not render scene if modal is open
      if (this.modalIsOpen || this.menuIsOpen) return

      this.mouse.x = this.mouseX.value
      this.mouse.y = this.resolution.y - this.mouseY.value
      this.material.uniforms.uMouse.value = this.mouse
      this.material.uniforms.uMouseVelocity.value = this.isFixedRadius ? 1.0 : Math.min(1.0, Math.max(0.01, this.mouseScale.value))
      this.texture.needsUpdate = true

      this.renderer.render({ scene: this.triangle })
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-dat-gui {
  z-index: 8;
  top: auto;
  bottom: 10%;
}

.video-container {
  user-select: none;
}

.video-fx {
  opacity: 0;
  transition: opacity 0.2s 0.2s;

  &.visible {
    opacity: 1;
  }
}

video {
  opacity: 0;
  pointer-events: none;
}
</style>
